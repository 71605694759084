import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import config from "../../../content/meta/config";

import DoorIcon from "!svg-react-loader!../../images/svg-icons/door.svg?name=door";
import Button from "../Button";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Link } from "gatsby";

const doorIconInlineStyle = {
  width: "2em",
  height: "2em",
  color: "#ffffff",
  marginRight: "10px"
};

const RoomPreviewContent = ({ room, theme }) => {
  const { title, detailsURL, description } = room;

  return (
    <div>
      <div className="title-row">
        <span className="icon">
          <DoorIcon style={doorIconInlineStyle} />
        </span>
        <span className="title">{title}</span>
      </div>
      <ul>
        {description.map(({ key, label, value }) => (
          <li key={key}>
            <div className="label">{label.th}:</div>
            <div className="value">{value.th}</div>
          </li>
        ))}
      </ul>

      <div className="more-details-row">
        <Link to={detailsURL}>อ่านเพิ่มเติม...</Link>
      </div>

      <div className="button-row">
        <a href={config.contact.line.url} target="_blank" rel="noopener noreferrer">
          <Button theme={theme} background={theme.color.special.line}>
            แอดไลน์ติดต่อจองห้องพัก
          </Button>
        </a>
        {/* <div className="button-row">
          <Button theme={theme} to={room.detailsURL}>
            ดูรายละเอียดเพิ่มเติม
          </Button>
        </div> */}
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .title-row {
          display: flex;
          margin: 1.6em 0;
        }

        .title {
          font-family: ${theme.font.family.simpleHeader};
          font-size: ${theme.font.size.m};
          color: ${theme.color.neutral.white};
          font-weight: normal;
          text-transform: uppercase;
          padding: 10px 0;
        }

        .icon {
          margin: 4px 0;

          :global(svg) {
            fill: #ffffff;
          }
        }

        ul {
          list-style: none;
          font-family: ${theme.font.family.thaiText};
          color: ${theme.color.neutral.white};
          line-height: 1.6em;
        }

        li {
          display: flex;
          margin: 0.5em 0;

          div.label {
            width: 100px;
            min-width: 100px;
          }
          div.value {
            flex: 1;
          }
        }

        .more-details-row {
          margin: 2em 0;
          font-family: ${theme.font.family.thaiText};
          :global(a) {
            font-weight: 600;
            color: ${theme.color.neutral.white};
          }
        }

        .button-row {
          margin: 2em 0;
        }
      `}</style>
    </div>
  );
};

RoomPreviewContent.propTypes = {
  room: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default RoomPreviewContent;
