import React from "react";
import PropTypes from "prop-types";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import config from "./../../../content/meta/config";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import RoomPreviewContent from "./RoomPreviewContent";
import Line from "../Line";

const RoomPreview = props => {
  const { theme, images } = props;

  if (typeof window === "undefined") {
    return <p>Server Render</p>;
  }

  return (
    <React.Fragment>
      <section className="wrapper">
        <div className="slideshow">
          <Splide
            options={{
              type: "fade",
              rewind: true,
              width: "100%",
              cover: true,
              height: "100%",
              autoplay: true
            }}
          >
            {images.map((image, index) => (
              <SplideSlide key={image}>
                <img src={image} alt={`Image ${index}`} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className="content">
          <div className="title">Rooms</div>
          <Line theme={theme} background="#ffffff75" />
          {config.rooms.map(room => (
            <RoomPreviewContent key={room.title} room={room} theme={theme} />
          ))}
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .wrapper {
          background: ${theme.color.brand.primaryBackground};
          width: 100%;
          height: auto;
          display: block;

          .slideshow {
            width: 100%;
            height: auto;
            background: #ffffff;
          }

          .content {
            width: 100%;
            height: 100%;
            padding: 20px;

            .title {
              font-family: ${theme.font.family.simpleHeader};
              font-size: ${theme.font.size.xxl};
              color: ${theme.color.neutral.white};
              font-weight: normal;
              text-transform: uppercase;
              padding: 20px 0;
            }
          }
        }

        @from-width desktop {
          .wrapper {
            display: flex;

            .slideshow {
              width: 55%;
              :global(.splide),
              :global(.splide__track),
              :global(.splide__list),
              :global(.splide__slide) {
                height: 100% !important;
              }
            }

            .content {
              width: 45%;
              padding: 20px 60px;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

RoomPreview.propTypes = {
  theme: PropTypes.object.isRequired,
  images: PropTypes.array
};

export default RoomPreview;
