import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { ThemeContext } from "../layouts";
import Hero from "../components/Hero";
// import Seo from "../components/Seo";
import Concept from "../components/Concept";
import RoomPreview, { RoomFullWidthPreview } from "../components/RoomPreview";
import MapPreview from "../components/Map";
import config from "../../content/meta/config";
import { FooterSlogan } from "../components/Footer";

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        },
        conceptImage1: {
          resize: { src: conceptImage1 }
        },
        conceptImage2: {
          resize: { src: conceptImage2 }
        },
        conceptImage3: {
          resize: { src: conceptImage3 }
        },
        roomImage1: {
          resize: { src: roomImage1 }
        },
        roomImage2: {
          resize: { src: roomImage2 }
        },
        roomImage3: {
          resize: { src: roomImage3 }
        }
      }
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile
    };

    const concepts = [...config.concepts];
    concepts[0].image = conceptImage1;
    concepts[1].image = conceptImage2;
    concepts[2].image = conceptImage3;

    return (
      <React.Fragment>
        <Helmet>
          <title>Peerapat Residence - หอพักพีรพัชร์ พหล 55</title>
          <meta
            name="description"
            content="Peerapat Residence - หอพักพีรพัชร์ 343 ซอย พหลโยธิน 53 ถนน พหลโยธิน แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220"
          />
          <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer>

        <ThemeContext.Consumer>
          {theme => (
            <>
              <Concept theme={theme} items={concepts} />
              <RoomPreview theme={theme} images={[roomImage1, roomImage2, roomImage3]} />
            </>
          )}
        </ThemeContext.Consumer>

        <RoomFullWidthPreview image={roomImage3} />

        <ThemeContext.Consumer>
          {theme => (
            <>
              <MapPreview theme={theme} />
              <FooterSlogan theme={theme} />
            </>
          )}
        </ThemeContext.Consumer>

        {/*<Seo facebook={facebook} />*/}

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    conceptImage1: imageSharp(fluid: { originalName: { regex: "/concept-casual/" } }) {
      resize(width: 450, quality: 100, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    conceptImage2: imageSharp(fluid: { originalName: { regex: "/concept-complete/" } }) {
      resize(width: 450, quality: 100, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    conceptImage3: imageSharp(fluid: { originalName: { regex: "/concept-map/" } }) {
      resize(width: 450, quality: 100, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    roomImage1: imageSharp(fluid: { originalName: { regex: "/room1/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    roomImage2: imageSharp(fluid: { originalName: { regex: "/room2/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
    roomImage3: imageSharp(fluid: { originalName: { regex: "/room3/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER, toFormat: WEBP) {
        src
      }
    }
  }
`;

//hero-background
