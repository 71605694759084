import React from "react";
import PropTypes from "prop-types";

const Line = props => {
  const { theme, background, className } = props;

  return (
    <React.Fragment>
      <div className={`line ${className}`} />

      {/* --- STYLES --- */}
      <style jsx>{`
        .line {
          height: 1px;
          background: ${background || theme.color.neutral.white};
          width: 100%;
        }
      `}</style>
    </React.Fragment>
  );
};

Line.propTypes = {
  theme: PropTypes.object.isRequired,
  background: PropTypes.string,
  className: PropTypes.string
};

export default Line;
