import React from "react";
import PropTypes from "prop-types";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const Concept = props => {
  const { theme, items } = props;

  if (typeof window === "undefined") {
    return <p>Server Render</p>;
  }

  return (
    <React.Fragment>
      <section className="container">
        <h1>Simplicity, Be Organised, Comfort & Relax</h1>
        <div className="concept-desktop">
          <ul>
            {items.map(item => (
              <li key={item.title}>
                <div className="image">
                  <img src={item.image} alt={item.title} />
                </div>
                <div className="title">{item.title}</div>
                <div className="description">{item.description}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="concept-mobile">
          <Splide
            options={{
              rewind: false,
              cover: false,
              fixedWidth: "360px",
              padding: {
                right: "60px"
              },
              arrows: false,
              pagination: false
            }}
          >
            {items.map(item => (
              <SplideSlide key={item.title}>
                <div className="image">
                  <img src={item.image} alt={item.title} />
                </div>
                <div className="title">{item.title}</div>
                <div className="description">{item.description}</div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        h1 {
          font-family: ${theme.font.family.simpleHeader};
          text-align: center;
          text-transform: uppercase;
          color: ${theme.color.brand.primary};
          font-size: 1.6em;
          font-weight: normal;
          letter-spacing: 0.05px;
        }

        .container {
          background: #FFFFF;
          padding: 20px;

          :global(ul) {
            display: flex;
            width: 85%;
            margin: 30px auto;
            text-align: center;
            list-style: none;

            :global(li) {
              width: 33%;
              padding: 0 20px 0 0;

              .image {
                width: 100%;
                height: 320px;

                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }

              .title {
                font-family: ${theme.font.family.thaiText};
                font-size: ${theme.font.size.m};
                color: ${theme.color.brand.primary};
                font-weight: 600;
                text-transform: uppercase;
                padding: 16px 0 8px 0;
              }

              .description {
                font-family: ${theme.font.family.thaiText};
                font-size: ${theme.font.size.xs};
                padding: 0 20px;
                line-height: ${theme.font.size.l};
                color: ${theme.color.neutral.gray.h};
              }
            }
          }
        }

        .concept-desktop {
          display: none;
        }

        .concept-mobile {
          margin-top: 20px;
        }

        @from-width tablet {
          .container {
            padding: 40px;
          }

          h1 {
            font-size: ${theme.font.size.xxl};
          }
        }

        @from-width desktop {
          .container {
            padding: 40px;
          }

          h1 {
            font-size: ${theme.font.size.xxxl};
          }

          .concept-mobile {
            display: none;
          }

          .concept-desktop {
            display: block;
          }

          .container {
            :global(ul) {
              :global(li) {
                img {
                  margin: 0 auto;
                }

                .title {
                  font-size: ${theme.font.size.l};
                  padding: 20px 0;
                }

                .description {
                  font-size: ${theme.font.size.s};
                  padding: 0 20px;
                }
              }
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Concept.propTypes = {
  theme: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired
};

export default Concept;
