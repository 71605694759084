import PropTypes from "prop-types";
import React from "react";
import { Parallax } from "react-parallax";

const RoomFullWidthPreview = ({ image }) => {
  return (
    <React.Fragment>
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={image}
        bgImageAlt="room overview"
        strength={-50}
      >
        <div className="content" />
      </Parallax>

      {/* --- STYLES --- */}
      <style jsx>{`
        .content {
          height: 360px;
        }

        @from-width desktop {
          .content {
            height: 600px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

RoomFullWidthPreview.propTypes = {
  image: PropTypes.string
};

export default RoomFullWidthPreview;
